import { useMemo, useState } from "react";
import { FloatType, MathUtils, Texture } from "three";

const SNOWFLAKE_DIAMETER = 32;
const PARTICLES_NUMBER = 1000;

const getRandomParticlePosition = (lowerLimit = -500, upperLimit = 500) => {
  return [
    MathUtils.randFloat(lowerLimit, upperLimit),
    MathUtils.randFloat(0, upperLimit),
    MathUtils.randFloat(lowerLimit, upperLimit),
  ];
};

const initParticlesPositions = (size) => {
  const points = [];

  for (let i = 0; i < size; i++) {
    points.push(...getRandomParticlePosition());
  }

  return new Float32Array(points);
};

// Using the canvas, draw a "snowflake"
const drawRadialGradation = (ctx, canvasRadius, canvasW, canvasH) => {
  ctx.save();
  const gradient = ctx.createRadialGradient(
    canvasRadius,
    canvasRadius,
    0,
    canvasRadius,
    canvasRadius,
    canvasRadius,
  );
  gradient.addColorStop(0, "rgba(255,255,255,1.0)");
  gradient.addColorStop(0.5, "rgba(255,255,255,0.5)");
  gradient.addColorStop(1, "rgba(255,255,255,0)");
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvasW, canvasH);
  ctx.restore();
};

// Create the snowflake texture using a native HTML canvas
const getSnowflakeTexture = (snowflakeDiameter = SNOWFLAKE_DIAMETER) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const canvasRadius = snowflakeDiameter / 2;

  canvas.width = snowflakeDiameter;
  canvas.height = snowflakeDiameter;

  drawRadialGradation(ctx, canvasRadius, canvas.width, canvas.height);

  const texture = new Texture(canvas);
  texture.type = FloatType;
  texture.needsUpdate = true;

  return texture;
};

export const useSnowing = ({ particlesNumber, snowflakeDiameter }) => {
  const [snowflakesPositions, setSnowflakesPositions] = useState(
    initParticlesPositions(particlesNumber ?? PARTICLES_NUMBER),
  );

  const snowflakeTexture = useMemo(
    () => getSnowflakeTexture(snowflakeDiameter),
    [snowflakeDiameter],
  );

  const moveSnowflakes = (factor) => {
    const newSnowflakesPositions = [];

    // Each snowflake position is composed by three array consecutive values
    for (let i = 0; i < snowflakesPositions.length; i += 3) {
      // Get current snowflake position
      const x = snowflakesPositions[i];
      const y = snowflakesPositions[i + 1];
      const z = snowflakesPositions[i + 2];

      // Calculate new snowflake position.
      // Calculation is taken from demo: https://codepen.io/tksiiii/pen/MRjWzv
      const calculatedX =
        x + Math.cos(factor * 0.001 * MathUtils.randFloatSpread(6)) * 0.1;
      const calculatedY = y - MathUtils.randFloat(6, 10) * 0.05;
      const calculatedZ =
        z + Math.sin(factor * 0.0015 * MathUtils.randFloatSpread(6)) * 0.1;

      // When snowflake has touched the floor then restart its position
      const newSnowflakePosition =
        calculatedY < 0
          ? getRandomParticlePosition()
          : [calculatedX, calculatedY, calculatedZ];
      newSnowflakesPositions.push(...newSnowflakePosition);
    }

    setSnowflakesPositions(new Float32Array(newSnowflakesPositions));
  };

  return { snowflakeTexture, snowflakesPositions, moveSnowflakes };
};
