import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { useContext } from "react";

import { NavbarContext } from "./navbar";

import logoLarge from "@images/trek-logo.large.png?url";
import logoSmall from "@images/trek-logo.small.svg?url";

const logoSmallWidth = "3.625rem";
const logoLargeWidth = "18.75rem";

const variants = {
  expanded: {
    width: logoLargeWidth,
    height: "23rem",
  },
  collapsed: {
    width: logoSmallWidth,
  },
};

export const LogoNavbar = ({ show }) => {
  const { isExpanded } = useContext(NavbarContext);

  return (
    <AnimatePresence initial={false} mode="popLayout">
      {show && (
        <div className="logo-container">
          <motion.img
            layout
            key={isExpanded ? logoLarge : logoSmall}
            src={isExpanded ? logoLarge : logoSmall}
            alt="trek logo"
            initial={{ width: isExpanded ? logoSmallWidth : logoLargeWidth }}
            exit={{ width: isExpanded ? logoSmallWidth : logoLargeWidth }}
            variants={variants}
          ></motion.img>
        </div>
      )}
    </AnimatePresence>
  );
};

LogoNavbar.displayName = "Logo Navbar";

LogoNavbar.propTypes = {
  show: PropTypes.bool,
};
